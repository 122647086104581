<template>
    <div class="map-page map-page-create-user" :class="{'map-loading': loading }">
        <div class="map-page-content map-center-layout">
            <h1 class="map-page-title">User Profile</h1>
            <p class="map-page-subtitle">Please fill in your information.</p>
            <loader />
            <form @submit.prevent="login">
                <base-input
                    placeholder="Name"
                    v-model="model.name"
                    :error="get_errors('name')"
                    >
                </base-input>
                <base-input
                    placeholder="Surname"
                    v-model="model.surname"
                    :error="get_errors('surname')"
                    >
                </base-input>
                <upload-input
                    v-model="model.profile_picture"
                    @file="profile_picture_changed"
                    icon-right="icon-upload"
                    accept="images"
                    placeholder="Upload profile picture (2Mb max.)"
                    >
                </upload-input>
                <profile-picture
                    :src="(profile_picture ? profile_picture : get_user_picture())"
                    >
                    <div class="map-profile-picture-info">
                        <p>Your profile picture will appear in a circle on your profile, so it is important to maintain a 1:1 aspect ratio. The best file types are JPG or PNG.</p>
                        <button
                            class="map-remove-picture"
                            v-if="profile_picture"
                            @click.prevent="remove_profile_picture"
                        >
                            Remove current profile picture <span>X</span>
                        </button>
                    </div>
                </profile-picture>
                <div class="map-user-email">
                    <base-input
                        type="email"
                        placeholder="E-mail"
                        v-model="model.email"
                        :disabled="!is_new && !change_email"
                        :error="get_errors('email')"
                        >
                    </base-input>
                    <Button
                        v-if="!is_new"
                        class="map-change-email"
                        color="primary"
                        @click="change_email = !change_email"
                        >
                        Change Email
                    </Button>
                </div>
                <base-input
                    v-model="model.organization"
                    placeholder="Organization"
                    >
                </base-input>
                <dropdown
                    placeholder="Country"
                    v-model="model.country_id"
                    :options="countries"
                    >
                </dropdown>
                <dropdown
                    v-if="user.role === 'super-admin' && !is_user_profile"
                    placeholder="Role"
                    v-model="model.role"
                    :options="roles"
                    >
                </dropdown>
                <base-textarea
                    v-model="model.bio"
                    placeholder="Bio"
                    >
                </base-textarea>
                <label>Your centers</label>
                <div class="map-center-list">
                    <checkbox v-model:checked="model.social_movement">
                        Social Movement
                    </checkbox>
                    <checkbox v-model:checked="model.countering_opposition">
                        Countering Opposition
                    </checkbox>
                    <checkbox v-model:checked="model.winning_narratives">
                        Winning Narratives
                    </checkbox>
                </div>
                <checkbox v-model:checked="model.receive_notifications">Receive notifications</checkbox>
                <template v-if="is_new || reset_password">
                    <base-input
                        :error="get_errors('password')"
                        v-model="model.password"
                        class="map-password-input"
                        type="password"
                        placeholder="Please enter password here"
                        >
                    </base-input>
                    <base-input
                        :error="get_errors('password_confirmation')"
                        v-model="model.password_confirmation"
                        class="map-password-input"
                        type="password"
                        placeholder="Please confirm password here"
                        >
                    </base-input>
                </template>
                <Button
                    v-if="!is_new"
                    class="map-reset-password"
                    color="primary"
                    @click.stop="reset_password = !reset_password"
                    >
                    RESET PASSWORD
                </Button>
                <footer>
                    <Button
                        class="map-cancel"
                        @click.prevent="$router.push({ name: 'UsersList' })"
                        >
                        CANCEL
                    </Button>
                    <Button
                        class="map-save"
                        @click.prevent="save"
                        >
                        SAVE
                    </Button>
                </footer>
            </form>
        </div>
        <map-sidebar class="map-sidebar-create-user"></map-sidebar>
    </div>
</template>

<script>
    import BaseInput from '@/components/Inputs/BaseInput';
    import BaseTextarea from '@/components/Inputs/BaseTextarea';
    import UploadInput from '@/components/Inputs/UploadInput';
    import Dropdown from '@/components/Inputs/Dropdown';
    import Checkbox from '@/components/Inputs/Checkbox';
    import Button from '@/components/Buttons/Button';
    import ProfilePicture from '@/components/ProfilePicture';
    import Loader from '@/components/Loader';
    import MapSidebar from '@/components/MapSidebar';

    import { CountryService, TopicService, UserService, RoleService } from '@/services';

    import useVuelidate from '@vuelidate/core';
    import { helpers, required, email, minLength } from '@vuelidate/validators';

    export default
    {
        name: 'UsersCreate',
        components:
        {
            ProfilePicture,
            BaseInput,
            BaseTextarea,
            UploadInput,
            Dropdown,
            Checkbox,
            Button,
            Loader,
            MapSidebar,
        },
        setup()
        {
            return { v$: useVuelidate() };
        },
        data()
        {
            return {
                loading: false,
                change_email: false,
                reset_password: false,
                countries: [],
                country: {},
                topics: [],
                roles: [],
                is_user_profile: this.$route.meta.user_profile,
                profile_picture: '',
                user_id: this.$route.params.id,
                is_new: !this.$route.params.id && !this.$route.meta.user_profile,
                model:
                {
                    name: '',
                    surname: '',
                    email: '',
                    organization: '',
                    profile_picture: {},
                    countries: [],
                    bio: '',
                    country_id: 0,
                    role: '',
                    centers: [],
                    receive_notifications: false,
                    password: '',
                    password_confirmation: '',
                },
            };
        },
        validations()
        {
            return {
                model:
                {
                    name: { required },
                    surname: { required },
                    email: { required, email },
                    password: { minLength: minLength(6) },
                    password_confirmation: {
                        sameAsPass: helpers.withMessage('Confirm password should be the same as password', () => this.model.password === this.model.password_confirmation)
                    },
                },
            };
        },
        computed:
        {
            user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        async created()
        {
            this.loading = true;

            this.countries = (await CountryService.all()).data;
            this.topics = (await TopicService.all()).data;

            this.countries.forEach((item) =>
            {
                item.value = item.id;
                item.label = item.name;
            });

            if (this.is_user_profile)
            {
                this.user_id = this.user.id;
                this.model = {...this.model, ...this.user};

                this.model.social_movement = this.model.centers.includes('Social Movement');
                this.model.countering_opposition = this.model.centers.includes('Countering Opposition');
                this.model.winning_narratives = this.model.centers.includes('Winning Narratives');

                this.loading = false;

                return ;
            }

            this.roles = (await RoleService.all()).data;

            this.roles.forEach((item) =>
            {
                item.value = item.name;
                item.label = item.title;
            });

            if (!this.is_new)
            {
                await this.get_user(this.$route.params.id);
            }

            this.loading = false;
        },
        methods:
        {
            async get_user(id)
            {
                const user_data = await UserService.get(id);

                this.model = {...this.model, ...user_data.data}

                this.model.social_movement = this.model.centers.includes('Social Movement');
                this.model.countering_opposition = this.model.centers.includes('Countering Opposition');
                this.model.winning_narratives = this.model.centers.includes('Winning Narratives');
            },

            async save()
            {
                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    return ;
                }

                if (this.country.id)
                {
                    this.model.country_id = this.country.id;
                }

                let form_data = new FormData();

                form_data.append('name', this.model.name);
                form_data.append('surname', this.model.surname);
                form_data.append('email', this.model.email);
                form_data.append('organization', this.model.organization);
                form_data.append('bio', this.model.bio);
                form_data.append('profile_picture', this.model.profile_picture);
                form_data.append('country_id', this.model.country_id);
                form_data.append('password', this.model.password);
                form_data.append('password_confirmation', this.model.password_confirmation);

                form_data.append('countering_opposition', this.model.countering_opposition ? 1 : 0);
                form_data.append('social_movement', this.model.social_movement ? 1 : 0);
                form_data.append('winning_narratives', this.model.winning_narratives ? 1 : 0);
                form_data.append('receive_notifications', this.model.receive_notifications ? 1 : 0);

                if (this.user.role === 'super-admin')
                {
                    form_data.append('role', this.model.role);
                }

                if (this.is_new && !this.is_user_profile)
                {
                    UserService.create(form_data).then(() =>
                    {
                        this.$router.push({ name: 'UsersList' });
                        this.$toast.success('User profile was successfully created!');
                    },
                    (error) =>
                    {
                        if (error.data.message)
                        {
                            this.$toast.error(error.data.message);
                        }
                        else
                        {
                            this.$toast.error('Something went wrong. Please try again!');
                        }
                    });
                }
                else
                {
                    UserService.update(this.user_id, form_data).then(() =>
                    {
                        this.$toast.success('User profile was successfully updated!');
                    },
                    (error) =>
                    {
                        if (error.data.message)
                        {
                            this.$toast.error(error.data.message);
                        }
                        else
                        {
                            this.$toast.error('Something went wrong. Please try again!');
                        }
                    });
                }
            },

            profile_picture_changed()
            {
                this.profile_picture = URL.createObjectURL(this.model.profile_picture);
            },

            remove_profile_picture()
            {
                this.model.profile_picture = {};
                this.profile_picture = undefined;
            },

            logout()
            {
                this.show = false;
                this.$router.push('/user/logout');
            },

            get_user_picture()
            {
                return (this.model.profile_picture_id ? process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.model.profile_picture_id : 0)
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            }
        }
    }
</script>
